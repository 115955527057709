import React from 'react';
import frontView from './../assets/front-view.jpg';  // Portrait
import outdoorSeating from './../assets/outdoor-seating.jpg';  // Landscape
import openSign from './../assets/open-sign.jpg';  // Landscape
import './LocationGallery.css';

const LocationGallery = () => {
  const images = [
    { src: frontView, alt: 'Front View' },       // Portrait
    { src: outdoorSeating, alt: 'Outdoor Seating' }, // Landscape
    { src: openSign, alt: 'Menu' }                  // Landscape
  ];

  return (
    <div className="gallery">
      {images.map((image, index) => (
        <div key={index} className={`gallery-item gallery-item-${index + 1}`}>
          <img src={image.src} alt={image.alt} />
        </div>
      ))}
    </div>
  );
};

export default LocationGallery;
