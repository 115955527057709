import React from 'react';
import './Menu.css';

const menuText = {
  Eng: {
    title: 'Menu',
    Appetizers: [
      { name: 'Cheese dip', price: '$4.99' },
      { name: 'Guacamole', price: '$5.99' },
      { name: 'Chorizo dip', price: '$6.99' },
      { name: 'Texas dip', price: '$7.99', description: 'A large bowl of cheese dip with grilled steak, chicken, and shrimp. Served with pico de gallo and choice of flour or corn tortillas.' }
    ],
    SaladsAndSoups: [
      { name: 'House salad', price: '$5.95', description: 'Iceberg lettuce, romaine lettuce, red cabbage, carrots, avocado, diced tomatoes & shredded cheese.' },
      { name: 'Parrillita salad', price: '$13.99', description: 'Grilled chicken served on a bed of iceberg lettuce, romaine lettuce, red cabbage, carrots, tomatoes, avocado, bacon, & shredded cheese.' },
      { name: 'Chicken soup', price: '$8.99' },
      { name: 'Shrimp soup', price: '$12.00' },
      { name: 'Tilapia & Shrimp soup', price: '$12.00' }
    ],
    Quesadillas: [
      { name: 'Only Cheese', price: '$7.00' },
      { name: 'Ground beef or Shredded chicken', price: '$9.99' },
      { name: 'Chorizo', price: '$10.50' },
      { name: 'Steak', price: '$13.50' },
      { name: 'Shrimp', price: '$13.50' },
      { name: 'Bistec', price: '$12.50' },
      { name: 'Vegan', price: '$11.50', description: 'Spinach, onions, bell peppers, and mushrooms' }
    ],
    Tacos: [
      { name: 'Beef fajita', price: '$3.50' },
      { name: 'Chicken fajita', price: '$3.25' },
      { name: 'Pastor', price: '$3.25' },
      { name: 'Campechana', price: '$3.25' },
      { name: 'Bistec', price: '$3.25' },
      { name: 'Tripa', price: '$3.50' },
      { name: 'Shrimp', price: '$3.50' },
      { name: 'Chorizo', price: '$7.00' },
      { name: 'Tacos Rancheros', price: '$11.25', description: 'Beef or chicken fajita tacos with pico de gallo, salsa ranchera, lettuce, sour cream, tomatoes, and rice and beans on the side.' },
      { name: 'Street Style Tacos', price: '$8.25', description: '4 mini tacos with your choice of any 2 meats, topped off with onions and cilantro.' },
      { name: 'Crunchy Tacos', price: '$9.50', description: '3 crispy tacos filled with ground beef or shredded chicken, topped with lettuce, tomatoes, cheese, and sour cream.' }
    ],
    Burritos: [
      { name: 'Super burrito', price: '$9.75', description: 'Shredded chicken or ground beef topped with lettuce, tomatoes, sour cream, and cheese' },
      { name: 'Burrito Mexicano', price: '$11.50', description: 'Shredded chicken or ground beef covered with cheese dip and served with rice and beans on the side.' },
      { name: 'Burrito Parrillita', price: '$13.00', description: 'Shredded chicken or ground beef topped with lettuce, tomatoes, sour cream, and cheese' },
      { name: 'Burrito Ranchero', price: '$13.00', description: 'Grilled chicken or steak with grilled onions and peppers, topped with salsa ranchera and melted cheese, served with rice and beans on the side.' },
      { name: 'Burrito del Mar', price: '$13.00', description: 'Grilled shrimp, tilapia, and pico de gallo, smothered with our homemade salsa ranchera and cheese dip. Served with Mexican rice and beans.' },
      { name: 'Burrito con todo', price: '$13.00', description: 'Grilled chicken or steak, Mexican rice and beans rolled in a large flour tortilla, topped with guacamole, pico de gallo, lettuce, and sour cream.' },
      { name: 'Veggie Burrito', price: '$13.00', description: 'Grilled spinach, onions, bell peppers, and mushrooms, served with lettuce, tomatoes, cheese, and sour cream.' }
    ],
    Plates: [
      { name: 'Fajita Plate', price: '$15.99', description: 'Grilled skirt steak, chicken and shrimp on a bed of bell peppers and onion, served with Mexican rice and beans, lettuce, pico de gallo, guacamole, and sour cream with flour or corn tortilla on the side.' },
      { name: 'Fajita Rice', price: '$14.99', description: 'Mexican rice with grilled peppers and onions. Topped with cheese dip, sour cream, and guacamole.' },
      { name: 'Fajita Nachos', price: '$14.95', description: 'Served on a bed of crispy tortilla chips, bell peppers, and onions, topped with lettuce, tomatoes, cheese dip, sour cream, and guacamole.' },
      { name: 'Big Nachos', price: '$11.99', description: 'A bed of tortilla chips, topped with beans, seasoned shredded chicken or ground beef, lettuce, tomatoes, cheese dip, and sour cream.' },
      { name: 'Pollo Ranchero', price: '$13.25', description: 'Grilled chicken breast, topped with cheese dip and salsa ranchera. Served with rice, beans, lettuce, sliced tomatoes, and sour cream.' },
      { name: 'Bistec Ranchero', price: '$13.25' }
    ],
    KidsMenu: [
      { name: 'Mini Cheeseburger', price: '$6.75' },
      { name: 'Mini Quesadilla', price: '$3.50' },
      { name: 'Combo #1', price: '$7.00', description: 'One taco, rice & beans.' },
      { name: 'Combo #2', price: '$7.00', description: 'One burrito, rice & beans, topped with tomato sauce & cheese' }
    ],
    Sides: [
      { name: 'Beans', price: '$2.99' },
      { name: 'Rice', price: '$2.99' },
      { name: 'Avocado Slices', price: '$0.50' },
      { name: 'Order of Avocado', price: '$2.99' },
      { name: 'Order of Pico de Gallo', price: '$2.25' },
      { name: 'Side of Pico de Gallo', price: '$0.50' },
      { name: 'Order of Chile Toreados with Onions', price: '$2.50' },
      { name: 'One Chile Toreado', price: '$0.50' },
      { name: 'French Fries', price: '$3.25' }
    ]
  }
};

const Menu = ({ language }) => {
  const menu = menuText[language] || menuText.Eng;
  const categories = Object.keys(menu).filter(key => key !== 'title'); // Get all categories except the title

  return (
    <section id="menu" className="menu">
      <h2>{menu.title}</h2>

      {/* Render All Categories */}
      <div className="menu-grid">
        {categories.map((category, index) => (
          <div key={index} className="menu-section">
            <h3>{category.replace(/([A-Z])/g, ' $1').trim()}</h3> {/* Format category names */}
            <ul>
              {menu[category].map((item, idx) => (
                <li key={idx}>
                  <strong>{item.name}</strong> - {item.price}
                  {item.description && <p>{item.description}</p>}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Menu;