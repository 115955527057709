import React, { useState, useEffect, useRef } from 'react';
import Menu from './Menu';
import AboutUs from './AboutUs';
import Location from './Location';
import './Header.css';

import logo from './../assets/grill.jpeg';
import Slideshow from './Slideshow';
import { FaBars } from 'react-icons/fa';

const Header = () => {
  const [language, setLanguage] = useState('eng');
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to manage menu visibility
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // State to track mobile view
  const menuRef = useRef(null); // Ref for the mobile menu


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Event listener to detect clicks outside the menu
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target) && !event.target.closest('.hamburger')) {
        setIsMenuOpen(false); // Close the menu if click is outside
      }
    };

    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  const changeLanguage = (lang) => {
    setLanguage(lang);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      <header className="header">
      {isMobile ? (
          <div className="hamburger" onClick={toggleMenu}>
            <FaBars />
          </div>
        ) : (
        <nav className="nav-left">
          <a href="#menu">{language === 'Eng' ? 'Menu' : 'Menú'}</a>
          <a href="#location">{language === 'Eng' ? 'Location' : 'Ubicación'}</a>
          <a href="#about">{language === 'Eng' ? 'About Us' : 'Sobre Nosotros'}</a>
        </nav>
        )}
        <div className="brand">
          <p>La</p>
          <div className="brand-name">
            <img src={logo} alt="La Parrillita Logo" className="logo" />
          </div>
          <p>Parrillita</p>
        </div>
        <div className="nav-right">
          <span
            className={`language-option ${language === 'Eng' ? 'active' : ''}`}
            onClick={() => changeLanguage('Eng')}
          >
            eng
          </span>
          <span> | </span>
          <span
            className={`language-option ${language === 'Esp' ? 'active' : ''}`}
            onClick={() => changeLanguage('Esp')}
          >
            esp
          </span>
        </div>
      </header>

      {isMobile && (
        <div className={`mobile-menu ${isMenuOpen ? 'open' : ''}`} ref={menuRef}>
          <nav className="mobile-nav">
            <a href="#menu" onClick={toggleMenu}>{language === 'Eng' ? 'Menu' : 'Menú'}</a>
            <a href="#about" onClick={toggleMenu}>{language === 'Eng' ? 'About Us' : 'Sobre Nosotros'}</a>
            <a href="#location" onClick={toggleMenu}>{language === 'Eng' ? 'Location' : 'Ubicación'}</a>
          </nav>
        </div>
      )}

      {/* Pass the selected language to all sections */}
      <Slideshow />
      <Menu language={language} />
      <AboutUs language={language} />
      <Location language={language} />
    </div>
  );
};

export default Header;
