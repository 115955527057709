import React from 'react';
import './AboutUs.css';

const text = {
  Eng: {
    title: 'About Us',
    description:
      'Established in 2018, La Parrillita is a family-owned food truck dedicated to serving fresh and delicious Mexican food. Our menu features a variety of mouth-watering options, including breakfast tacos, regular tacos (pastor, beef fajita, tripa), burritos, and salads.'
  },
  Esp: {
    title: 'Sobre Nosotros',
    description:
      'Establecido en 2018, La Parrillita es un camión de comida familiar dedicado a servir comida mexicana fresca y deliciosa. Nuestro menú ofrece una variedad de opciones deliciosas, incluidos tacos de desayuno, tacos regulares (pastor, fajita de res, tripa), burritos y ensaladas.'
  }
};

const AboutUs = ({ language }) => {
  console.log(language)
  const aboutText = text[language] || text.Eng;
  console.log(aboutText)

  return (
    <section id="about" className='about-us'>
      <h2>{aboutText.title}</h2>
      <p>{aboutText.description}</p>
    </section>
  );
};

export default AboutUs;
