import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import the library's CSS
import './Slideshow.css'; // Additional custom CSS if needed

import burger from './../assets/hamburger.jpg';
import shrimpSoup from './../assets/shrimp-soup.jpg';
import nachos from './../assets/nachos.jpg';
import mexicanPizza from './../assets/mexican-pizza.jpg';
import pastorTacos from './../assets/pastor-tacos.jpg';
import chilaquiles from './../assets/chilaquiles.jpg';

const slides = [
  { src: burger, alt: 'Burger' },
  { src: shrimpSoup, alt: 'Shrimp Soup' },
  { src: nachos, alt: 'Nachos' },
  { src: mexicanPizza, alt: 'Mexican Pizza' },
  { src: pastorTacos, alt: 'Pastor Tacos' },
  { src: chilaquiles, alt: 'Chilaquiles' },

];

const Slideshow = () => {
  return (
    <Carousel
      autoPlay
      interval={5000}
      infiniteLoop
      showThumbs={false}
      showStatus={false}
      showArrows={true}
      dynamicHeight={false}
      className="slideshow"
    >
      {slides.map((slide, index) => (
        <div key={index}>
          <img src={slide.src} alt={slide.alt} className="slide-image" />
        </div>
      ))}
    </Carousel>
  );
};

export default Slideshow;
